import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CheckboxCollectionRenderingData, CheckboxRenderingData } from '../checkbox/checkbox-renderings/checkbox-default-rendering.types';
import { UndoBehaviorSubject } from '@util/classes/undo-behavior-subject.class';

export interface CheckboxItem<V = any, R = any> {
  label?: string;
  labelTranslationKey?: string;
  value: V;
  selected?: boolean;
  hidden?: boolean;
  isFreeText?: boolean;
  disabled?: boolean;
  __itemRenderingData?: CheckboxRenderingData<R>;
  injectedRenderingData?: R;
}

export class CheckboxGroupDataSource<V = any, R = any> {

  private _markForChangeSubject = new Subject<void>();
  private _selectedItemsBehaviorSubject = new UndoBehaviorSubject<CheckboxItem<V, R>[]>([]);
  private _itemsBehaviorSubject = new BehaviorSubject<CheckboxItem<V, R>[]>(null);
  private _renderingDataBehaviorSubject = new BehaviorSubject<CheckboxCollectionRenderingData<R>>(null);
  private _isRadioButtonGroup: boolean;

  /**
   * index of the focused item (by arrow keys); -1 means = no item is focused
   */
  private focusIndex$ = new BehaviorSubject<number>(-1);

  get markForChange(): Observable<void> {
    return this._markForChangeSubject.asObservable();
  }

  get items(): CheckboxItem<V, R>[] {
    return this._itemsBehaviorSubject.value;
  }

  set items(value: CheckboxItem<V, R>[]) {
    if (this.renderingData) {
      value?.forEach(item => {
        item.__itemRenderingData = item.__itemRenderingData || {...this.renderingData, data: item.injectedRenderingData};
      });
    }
    this._itemsBehaviorSubject.next(value);
    const found = this.items.filter(item => item.selected);
    if (found) {
      this.setSelectedItems(found);
    }
  }

  get items$(): Observable<CheckboxItem<V, R>[]> {
    return this._itemsBehaviorSubject.asObservable();
  }

  get selectedItems(): CheckboxItem<V, R>[] {
    return this._selectedItemsBehaviorSubject.value;
  }

  get selectedItems$(): Observable<CheckboxItem<V, R>[]> {
    return this._selectedItemsBehaviorSubject.asObservable();
  }

  get renderingData(): CheckboxCollectionRenderingData {
    return this._renderingDataBehaviorSubject.value;
  }

  set renderingData(value: CheckboxCollectionRenderingData) {
    if (value) {
      this.items?.forEach(item => {
        item.__itemRenderingData = item.__itemRenderingData || {...this.renderingData, data: item.injectedRenderingData};
      });
    }
    this._renderingDataBehaviorSubject.next(value);
  }

  get value(): V {
    return this.selectedItems?.[0]?.value;
  }

  get isRadioButtonGroup(): boolean {
    return this._isRadioButtonGroup;
  }

  set isRadioButtonGroup(value: boolean) {
    this._isRadioButtonGroup = value;
  }

  selectItemGuard: (item: CheckboxItem<V, R>, ...args: any[]) => boolean;

  constructor(items?: CheckboxItem<V, R>[], renderingData?: CheckboxCollectionRenderingData) {
    if (renderingData) {
      this.renderingData = renderingData;
    }
    this.items = items || [];
  }

  triggerMarkForChange() {
    this._markForChangeSubject.next();
  }


  toggleItem(item: CheckboxItem<V, R>, state?: boolean) {

    if (item.selected !== state && !item.disabled) {

      if (!this.selectItemGuard || this.selectItemGuard(item)) {

        if (this.isRadioButtonGroup) {
          this.items.forEach(it => it.selected = false);
          item.selected = true;
          this.setSelectedItems([item]);
        } else {
          item.selected = state;
          const found = this.items.filter(item => item.selected);
          this.setSelectedItems(found);
        }

      }

    }
  }

  selectItem(item: CheckboxItem<V, R>) {

    if (!this.selectItemGuard || this.selectItemGuard(item)) {

      if (this.isRadioButtonGroup) {
        this.items.forEach(it => it.selected = false);
        item.selected = true;
        this.setSelectedItems([item]);
      } else {
        item.selected = true;
        const found = this.items.filter(item => item.selected);
        this.setSelectedItems(found);
      }
    }
  }

  selectItemWithValue(value: V) {
    const found = this.items.find(it => it.value === value);
    if (found) {
      this.selectItem(found);
    }
    return found;
  }

  selectAll(onlyModel?: boolean) {
    if (!this.isRadioButtonGroup) {
      this.items.forEach(it => it.selected = true);
      if (onlyModel) {
        this.setSelectedItems(this.items);
      }
    }
  }

  private setSelectedItems(items: CheckboxItem<V, R>[]) {
    items ||= [];

    // check if old entry and new entry have a different length
    let differentLength = this._selectedItemsBehaviorSubject.value.length !== items.length;

    // if yes, then they are different
    let different = differentLength;

    // if they have the same length then here they seem to be not different but are they truely?
    // don't they have at least one different entry (note: same entries but different order is also different)
    if (!different) {
      different = this._selectedItemsBehaviorSubject.value.some((entry, i) => {
        return (entry?.value !== items[i].value);
      });
    }

    if (different) {
      this._selectedItemsBehaviorSubject.next(items);
    }
  }

  deselectAll(onlyModel?: boolean) {
    this.items.forEach(it => it.selected = false);
    if (!onlyModel) {
      this.setSelectedItems([]);
    }
    this.triggerMarkForChange();
  }

  setUntouched() {
    this.items.forEach(it => it.selected = false);
    this.setSelectedItems([]);
  }

  search(term: string, searchFunction?: (term: string, item: CheckboxItem) => boolean) {

    const defaultSearchFunction = (term: string, item: CheckboxItem): boolean => {
      return item?.label?.toLowerCase()?.includes(term?.toLowerCase());
    };

    searchFunction = searchFunction || defaultSearchFunction;

    this.items.forEach(item => item.hidden = !searchFunction(term, item));

    this.resetFocus();
    this.triggerMarkForChange();

  }

  resetSearch() {
    this.items.forEach(item => item.hidden = false);
    this.resetFocus();
    this.triggerMarkForChange();
  }

  /**
   * reverts a number of steps if it is possible - returns true if it was
   */
  resetItemSelectionHistory(stepBackwards = 1): boolean {
    // is it possible to go so many steps backwards?
    const possible = stepBackwards >= 0 && this._selectedItemsBehaviorSubject.howManyUndos >= stepBackwards;

    if (possible) {
      this._selectedItemsBehaviorSubject.undoMany(stepBackwards);
    }
    return possible;
  }

  resetUntilNextSelection(): boolean {
    const untilNextSelection = this._selectedItemsBehaviorSubject.getHowManyUndoUntil((entry, i) => (entry.length > 0), 1);
    return this.resetItemSelectionHistory(untilNextSelection);
  }

  resetFocus() {
    if (this.focusIndex$.value !== -1) {
      this.focusIndex$.next(-1);
    }
  }

  focusUp(): CheckboxItem<V, R> {
    return this.focusMove('up');
  }

  focusDown(): CheckboxItem<V, R> {
    return this.focusMove('down');
  }

  getFocusedItem(): CheckboxItem<V, R> {
    if (this.focusIndex$.value === -1) {
      return null;
    }
    const i = this.focusIndex$.value;
    const visItems = this.items.filter(item => !item.hidden);
    if (visItems[i]) {
      return visItems[i];
    }
  }

  toggleFocusedItem(): CheckboxItem<V, R> {
    const item = this.getFocusedItem();
    if (item) {
      this.toggleItem(item);
    }
    return item;
  }


  private getFingerprintOfItems(thisItems?: CheckboxItem<V, R>[]): string {
    return thisItems.map<string>(item => 'v:' + item.value)?.sort()?.join(',');
  }

  private focusMove(dir: 'up' | 'down'): CheckboxItem<V, R> {
    const curi = this.focusIndex$.value;
    const visItems = this.items.filter(item => !item.hidden);
    const max = visItems.length;


    // if it is -1, focus is deactivated and
    if (curi === -1) {
      this.focusIndex$.next((dir === 'up' ? (max - 1) : 0));
    } else {
      let res: number;
      res = (dir === 'up' ? (curi - 1) : (curi + 1));
      if (res < 0) {
        res = max - 1;
      }
      if (res >= max) {
        res = 0;
      }
      this.focusIndex$.next(res);
    }

    return visItems[this.focusIndex$.value];
  }


}
