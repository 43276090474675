import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { coerceBoolean } from '@util/functions/objects';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CheckboxGroupDataSource, CheckboxItem } from './checkbox-group-data-source';


@Component({
  selector: 'app-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.scss'],
  exportAs: 'app-checkbox-group',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxGroupComponent implements OnInit, OnDestroy {

  private _markForChangeSubscription = new Subscription();
  private _dataSource: CheckboxGroupDataSource;
  private _isRadioButtonGroupBehaviorSubject = new BehaviorSubject<boolean>(false);

  noItemsTranslationKey = 'dropdown.noItems';

  @HostBinding('class')
  get classes() {
    const arr: string[] = [
      'app-checkbox-group-display-' + this.display,
    ];
    if (this.readonly) {
      arr.push('app-checkbox-group-readonly');
    }
    if (this.disabled) {
      arr.push('app-checkbox-group-disabled');
    }
    if (this.touched) {
      arr.push('app-checkbox-group-touched');
    } else {
      arr.push('app-checkbox-group-untouched');
    }
    return arr;
  }

  @Input()
  groupLabel: string;

  @Input()
  hideBox: boolean;

  get dataSource(): CheckboxGroupDataSource {
    return this._dataSource;
  }

  @Input()
  set dataSource(value: CheckboxGroupDataSource) {
    this._markForChangeSubscription?.unsubscribe();
    this._markForChangeSubscription = new Subscription();
    const sub1 = value?.markForChange.subscribe(() => this.cdr.markForCheck());
    const sub2 = this._isRadioButtonGroupBehaviorSubject.subscribe(res => value.isRadioButtonGroup = res);
    this._markForChangeSubscription.add(sub1);
    this._markForChangeSubscription.add(sub2);
    this._dataSource = value;
  }

  @Input()
  display: 'column' | 'row' = 'row';

  @Input()
  disabled: boolean;

  @Input()
  readonly: boolean;

  @Input()
  hideFromTabIndex: boolean;

  get isRadioButtonGroup(): boolean {
    return this._isRadioButtonGroupBehaviorSubject.value;
  }

  @Input()
  set isRadioButtonGroup(value: boolean) {
    this._isRadioButtonGroupBehaviorSubject.next(coerceBoolean(value));
  }

  get value(): any {
    return this.dataSource?.value;
  }

  get touched(): boolean {
    return !!this.dataSource?.selectedItems;
  }

  get untouched(): boolean {
    return !this.touched;
  }

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this._markForChangeSubscription?.unsubscribe();
  }

  clickOnItem(item: CheckboxItem, state: boolean) {
    this.dataSource?.toggleItem(item, state);
  }

  setUntouched() {
    this.dataSource?.setUntouched();
  }

  getVisibleItems(items: CheckboxItem[]): CheckboxItem[] {
    return items.filter(item => !item.hidden);
  }

  getFocusedClass(focusIndex: number, curIndex: number) {
    return (focusIndex === curIndex) ? 'focused-item' : '';
  }


}
