<ng-container *ngIf="dataSource">

  <div *ngIf="groupLabel" class="label-container">
    <label>{{groupLabel}}</label>
  </div>

  <div class="checkboxes checkboxes-display-{{display}}">

    <ng-container [getVar]="getVisibleItems(dataSource.items)" #dynItems="getVar">

      <ng-template #noItems>
        <div class="no-item-label">{{ noItemsTranslationKey | cxTranslate }}</div>
      </ng-template>

      <ng-container [getVar]="dataSource.focusIndex$ | async" #focusIndex="getVar">

        <ng-container *ngIf="dynItems.value.length; else noItems">
          <ng-container *ngFor="let item of dynItems.value; index as i">
            <app-checkbox
              [label]="item.labelTranslationKey ? (item.labelTranslationKey | cxTranslate) : item.label"
              [class]="[getFocusedClass(focusIndex.value, i)]"
              [checked]="item.selected"
              [displayAsRadioButton]="!!isRadioButtonGroup"
              (checkedChange)="clickOnItem(item, $event)"
              [hideFromTabIndex]="hideFromTabIndex"
              [readonly]="readonly"
              [disabled]="disabled || item.disabled"
              [hideBox]="hideBox"
              [renderingData]="item.__itemRenderingData"
            ></app-checkbox>
          </ng-container>
        </ng-container>

      </ng-container>
    </ng-container>

  </div>

</ng-container>
